body, html {
  font-family: 'Nunito', sans-serif;
}

@media (max-width:991.98px) {
  .padding {
      padding: 1.5rem
  }
}

@media (max-width:767.98px) {
  .padding {
      padding: 1rem
  }
}

.padding {
  padding: 5rem
}

.card {
 position: relative;
 margin: 10px !important;
 display: flex;
 width: 350px;
 flex-direction: column;
 min-width: 0;
 word-wrap: break-word;
 background-color: #fff;
 background-clip: border-box;
 border: 0px solid #d2d2dc;
 border-radius: 11px;
 -webkit-box-shadow: 0px 0px 5px 0px rgb(249, 249, 250);
 -moz-box-shadow: 0px 0px 5px 0px rgba(212,182,212,1);
 box-shadow: 0px 0px 5px 0px rgb(161, 163, 164);
}

.card .card-body {
     padding: 1rem 1rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

p {
  font-size: 0.875rem;
  margin-bottom: .5rem;
  line-height: 1.5rem
}

.profile{
        margin-top: 16px;
 margin-left: 11px;
}

.profile-pic{
  width: 58px;
}

.cust-name{
  font-size: 18px;
}

.cust-profession{
  font-size: 10px;
}

.items {
 width: 100%;
 margin: 0px auto;
 margin-top: 100px;
}

.slick-slide {
 margin: 10px
}